import { useState } from "react"
import NoEvents from "./NoEvents.tsx"
import { useFetch } from "./useFetch.tsx"
import _bondingEvent from "./Event.tsx"
import MultiSelectDropDown from "./MultiSelectDropDown.tsx"
import Fuse from "https://cdn.jsdelivr.net/npm/fuse.js@7.0.0/dist/fuse.mjs"
import { ControlType, addPropertyControls } from "framer"
import SearchInput from "./SearchInput.tsx"
/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/components/auto-sizing
 *
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight auto
 */
export default function Events(props) {
    const [search, setSearch] = useState("")

    const { data: events } = useFetch<API_V0_Event>(
        "https://events.bonding.de/api/v0/eventList"
    )

    const [selectedCategories, setSelectedCategories] = useState(
        props.availableCategories
    )

    const [selectedLocalGroups, setSelectedLocalGroups] = useState(
        props.availableLocalGroups
    )

    console.log({ props })

    const filteredEvents =
        events
            ?.map(adaptEventResource)
            ?.sort(
                (a, b) =>
                    Date.parse(a.startDate?.date || "") -
                    Date.parse(b.startDate?.date || "")
            )
            .filter((item) => {
                if (!selectedLocalGroups || selectedLocalGroups.length === 10) {
                    return true
                } else {
                    return selectedLocalGroups.includes(item.localGroup)
                }
            })
            .filter((item) => {
                if (
                    !selectedCategories ||
                    (selectedCategories.length ===
                        props.availableCategories.length &&
                        props.availableCategories.length > 10)
                ) {
                    return true
                } else {
                    return selectedCategories.includes(item.type)
                }
            }) || []

    const fuse = new Fuse(filteredEvents, {
        keys: ["title", "location", "description", "localGroup"],
        minMatchCharLength: 3,
    })

    const searchedEvents =
        search.length >= 3
            ? fuse
                  .search(search, {
                      limit: props.count ?? 10,
                  })
                  .map((item) => item.item)
            : filteredEvents.slice(0, props.count ? props.count : undefined)

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "1.25em",
                rowGap: "2em",
                paddingBlock: "2em",
                alignItems: "center",
            }}
        >
            {props.showNavigation && (
                <header
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        columnGap: "2em",
                        rowGap: "1.5em",
                    }}
                >
                    <SearchInput search={search} setSearch={setSearch} />
                    {props.showLocalGroupSelector && (
                        <MultiSelectDropDown
                            options={props.availableLocalGroups}
                            placeholder="Wähle deine Standorte aus"
                            allValuePlaceholder="Alle Standorte"
                            value={selectedLocalGroups}
                            onChange={setSelectedLocalGroups}
                        />
                    )}
                    {props.showCategoriesSelector && (
                        <MultiSelectDropDown
                            options={props.availableCategories}
                            placeholder="Wähle Eventkategorien aus"
                            allValuePlaceholder="Alle Events"
                            value={selectedCategories}
                            onChange={setSelectedCategories}
                        />
                    )}
                </header>
            )}

            {searchedEvents.length === 0 ? (
                <NoEvents />
            ) : (
                <div
                    style={{
                        display: "grid",
                        gap: "1em",
                        gridTemplateColumns:
                            "repeat(auto-fill, minmax(min(40ch, 100%), 1fr))",
                        width: "100%",
                        alignContent: "center",
                    }}
                >
                    {searchedEvents.map((event) => {
                        return <_bondingEvent key={event.id} event={event} />
                    })}
                </div>
            )}
        </div>
    )
}

addPropertyControls(Events, {
    count: {
        type: ControlType.Number,
        min: 2,
        step: 1,
        max: 50,
    },
    availableLocalGroups: {
        type: ControlType.Array,
        control: {
            type: ControlType.String,
        },
        defaultValue: [
            "Aachen",
            "Berlin",
            "Braunschweig",
            "Dresden",
            "Erlangen",
            "Hamburg",
            "Kaiserslautern",
            "Karlsruhe",
            "Nürnberg",
            "Stuttgart",
        ],
    },
    availableCategories: {
        type: ControlType.Array,
        control: {
            type: ControlType.String,
        },
        defaultValue: [
            "Engineering Competition",
            "European Workshop",
            "Exkursion",
            "Fallstudie",
            "Industry Night",
            "Infoabend",
            "Infostand",
            "Kamin-Abend",
            "Kompass",
            "Messe",
            "nicht bonding",
            "Runder Tisch",
            "Semesterplaner",
            "Sonstige Events",
            "Thementag",
            "Training",
            "Vortrag",
            "Workshop",
            "Unbekannt",
        ],
    },
    showNavigation: {
        type: ControlType.Boolean,
    },
    showLocalGroupSelector: {
        type: ControlType.Boolean,
        hidden: ({ showNavigation }) => !showNavigation,
    },
    showCategoriesSelector: {
        type: ControlType.Boolean,
        hidden: ({ showNavigation }) => !showNavigation,
    },
})

type API_V0_Event = {
    Titel: string
    HSG: string
    ID: string
    Anmeldung: string
    StartDatum: string
    StartUhrzeit: string
    EndeDatum: string
    EndeUhrzeit: string
    Typ: string
    Ort: string
    WebText: string
}

type EventCategory =
    | "Engineering Competition"
    | "European Workshop"
    | "Exkursion"
    | "Fallstudie"
    | "Industry Night"
    | "Infoabend"
    | "Infostand"
    | "Kamin-Abend"
    | "Kompass"
    | "Messe"
    | "nicht bonding"
    | "Runder Tisch"
    | "Semesterplaner"
    | "Sonstige Events"
    | "Thementag"
    | "Training"
    | "Vortrag"
    | "Workshop"
    | "Unbekannt"

function strip(html: string) {
    const parsedHTML = new DOMParser().parseFromString(html, "text/html")
    const text = parsedHTML.body.textContent

    if (/(<([^>]+)>)/gi.test(text)) {
        return strip(text)
    }

    return text || ""
}

function formatEventType(event: API_V0_Event) {
    return event.Typ.substring(0, event.Typ.length - 9) as EventCategory
}

export type Event = {
    title: string
    location: string
    localGroup: string
    id: string
    signUpStatus: string
    type: EventCategory
    description: string
    startDate:
        | {
              minute: number
              hour: number
              day: number
              month: string
              year: number
              date: string
          }
        | undefined
    endDate:
        | {
              minute: number
              hour: number
              day: number
              month: string
              year: number
              date: string
          }
        | undefined
}

function adaptEventResource(event: API_V0_Event): Event {
    const startDate = new Date(event.StartDatum) || undefined
    const endDate = new Date(event.EndeDatum) || undefined
    const endTime = new Date(event.EndeUhrzeit) || undefined
    const startTime = new Date(event.StartUhrzeit) || undefined
    const months = [
        "Jan",
        "Feb",
        "Mär",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dez",
    ]

    return {
        title: event.Titel,
        location: event.Ort,
        localGroup: event.HSG,
        id: event.ID,
        signUpStatus: event.Anmeldung,
        type: formatEventType(event),
        description: strip(event.WebText),
        startDate: event.StartDatum
            ? {
                  minute: startTime.getMinutes(),
                  hour: startTime.getHours(),
                  day: startDate.getDate(),
                  month: months[startDate.getMonth()],
                  year: startDate.getFullYear(),
                  date: event.StartDatum,
              }
            : undefined,
        endDate: event.EndeDatum
            ? {
                  minute: endTime.getMinutes(),
                  hour: endTime.getHours(),
                  day: endDate.getDate(),
                  month: months[endDate.getMonth()],
                  year: endDate.getFullYear(),
                  date: event.EndeDatum,
              }
            : undefined,
    }
}
